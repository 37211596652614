import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

interface LayoutPropType {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutPropType) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 500,
      easing: 'ease-out-cubic',
    });
  }, []);

  return (
    <div className="font-inter antialiased bg-gray-900 text-gray-100 tracking-tight relative">
      <Header />
      <main className="w-full">{children}</main>
      <Footer />
    </div>
  );
};

export { Layout };
