import React from 'react';

const Footer = () => (
  <footer className="border-t border-gray-800">
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      {/* Blocks */}
      <div className="flex items-center justify-center py-12">
        {/* 1st block */}
        <div className="">
          <div className="mb-2">
            <a
              className="flex items-center"
              href="https://www.linkedin.com/in/abhishek-s-4865b7b9/"
              aria-label="Abhishek Shaji"
            >
              <svg
                className="w-8 h-8"
                viewBox="0 0 32 32"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <radialGradient
                    cx="50%"
                    cy="89.845%"
                    fx="50%"
                    fy="89.845%"
                    r="108.567%"
                    gradientTransform="matrix(-.00915 -.82755 .99996 -.00757 -.394 1.319)"
                    id="logo1-b"
                  >
                    <stop stopColor="#3B82F6" stopOpacity=".64" offset="0%" />
                    <stop stopColor="#F472B6" stopOpacity=".876" offset="100%" />
                  </radialGradient>
                  <radialGradient
                    cx="50%"
                    cy="89.845%"
                    fx="50%"
                    fy="89.845%"
                    r="108.567%"
                    gradientTransform="matrix(-.00915 -.82755 .99996 -.00757 -.394 1.319)"
                    id="logo1-d"
                  >
                    <stop stopColor="#3B82F6" stopOpacity=".64" offset="0%" />
                    <stop stopColor="#D375C2" stopOpacity=".833" offset="50.358%" />
                    <stop stopColor="#FBCFE8" stopOpacity=".876" offset="100%" />
                  </radialGradient>
                  <path
                    d="M12 32c8-6.915 12-12.582 12-17 0-6.627-5.373-12-12-12S0 8.373 0 15c0 4.418 4 10.085 12 17Z"
                    id="logo1-a"
                  />
                  <path
                    d="M20 29c8-6.915 12-12.582 12-17 0-6.627-5.373-12-12-12S8 5.373 8 12c0 4.418 4 10.085 12 17Z"
                    id="logo1-c"
                  />
                </defs>
                <g fill="none" fillRule="evenodd">
                  <use
                    fill="url(#logo1-b)"
                    opacity=".64"
                    transform="matrix(1 0 0 -1 0 35)"
                    xlinkHref="#logo1-a"
                  />
                  <use fill="url(#logo1-d)" opacity=".961" xlinkHref="#logo1-c" />
                </g>
              </svg>
              <span className="ml-3 text-2xl font-bold">Abhishek Shaji</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="b w-full py-6 text-center text-sm opacity-50">
      © {new Date().getFullYear()} Abhishek Shaji. All Rights Reserved
    </div>
  </footer>
);

export { Footer };
